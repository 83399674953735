@tailwind base;
@tailwind components;
@tailwind utilities;

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-display;
}

h1 {
  @apply text-3xl sm:text-4xl md:text-6xl;
}

h2 {
  @apply text-2xl sm:text-3xl md:text-4xl;
}

h3 {
  @apply text-lg sm:text-xl md:text-2xl;
}

h4 {
  @apply text-xl uppercase font-body tracking-widest;
}

button {
  @apply font-display tracking-widest;
}

body {
  @apply text-gray-200 font-body text-lg leading-loose;
}

#deckgl-wrapper {
  background: rgb(146 177 185);
  @apply overflow-auto;
}

/* Prevent interference with Bridge modal by setting z-index */
.web3modal-modal-lightbox {
  z-index: 11 !important;
}

.shimmer.background-animate {
  background-size: 500%;
}

.fast {
  animation: animation-name 3s ease infinite;
}

.slow {
  animation: animation-name 8s ease infinite;
}

@keyframes animation-name {
  0%,
  100% {
    background-position: 25% 50%;
  }

  50% {
    background-position: 90% 50%;
  }
}
